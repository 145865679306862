// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import { cfdiScripts } from "../../../scripts/cfdi"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Emitir una Factura <br /><span className="small">(Campos minimos)</span></h2>
                <p>
                    Antes de proceder a emitir una factura, habrá sido necesario haber creado un <b>cliente de prueba o producción</b> y haber cargado sus certificados.
                </p>
                <p>
                    Notarás que <b>en el cuerpo de request no se agrega el Emisor</b>, esto porque identificamos el emisor con el AuthToken, y no necesitamos agregarlo a la factua.
                </p>
                <p>
                    Recuerda que en CFDI 4.0 el SAT revisará que el Nombre, RFC y Régimen Fiscal del <b>receptor coincidan con los datos que tienen registrados literalmente.</b> <br />
                    Es por ello que el error más común es que el nombre del receptor no coincida con el que el SAT tiene registrado.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/cfdi/stamp/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Receptores de Prueba
                </h3>
                <p>
                    Para emitir facturas de prueba, deberás usar los siguientes RFCs de prueba, no será posible usar un RFC real ya que el ambiente de pruebas hace estas mismas validaciones y no permitirá emitir facturas con RFCs reales.:
                </p>
                <table className="inline table table-striped table-condensed">
                    <thead>
                        <tr className="row0">
                            <th className="rfc">RFC</th><th className="name"> Nombre (Razón social) </th>
                            <th className="rfc_name">RFC
                                <br />
                                Nombre (Razón social)
                            </th>
                            <th className="col2"> Domicilio fiscal </th><th className="col3"> Régimen Fiscal </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="row1">
                            <td className="rfc">MASO451221PM4 </td><td className="name"> MARIA OLIVIA MARTINEZ SAGAZ  </td>
                            <td className="rfc_name">MASO451221PM4 <br /> MARIA OLIVIA MARTINEZ SAGAZ  </td>
                            <td className="col2"> 80290 </td><td className="col3"> 605, 612, 621, 625 </td>
                        </tr>
                        <tr className="row2">
                            <td className="rfc">AABF800614HI0 </td><td className="name"> FELIX MANUEL ANDRADE BALLADO </td>
                            <td className="rfc_name">AABF800614HI0 <br /> FELIX MANUEL ANDRADE BALLADO </td>
                            <td className="col2"> 86400 </td><td className="col3"> 605, 612, 621, 625, 626 </td>
                        </tr>
                        <tr className="row3">
                            <td className="rfc">CUSC850516316 </td><td className="name"> CESAR OSBALDO CRUZ SOLORZANO </td>
                            <td className="rfc_name">CUSC850516316 <br /> CESAR OSBALDO CRUZ SOLORZANO </td>
                            <td className="col2"> 45638 </td><td className="col3"> 605, 612, 621, 625, 626 </td>
                        </tr>
                        <tr className="row4">
                            <td className="rfc">ICV060329BY0 </td><td className="name"> INMOBILIARIA CVA </td>
                            <td className="rfc_name">ICV060329BY0 <br /> INMOBILIARIA CVA </td>
                            <td className="col2"> 33826 </td><td className="col3"> 601, 626 </td>
                        </tr>
                        <tr className="row5">
                            <td className="rfc">ABC970528UHA </td><td className="name"> ARENA BLANCA SCL DE CV  </td>
                            <td className="rfc_name">ABC970528UHA <br /> ARENA BLANCA SCL DE CV  </td>
                            <td className="col2"> 80290 </td><td className="col3"> 601, 626 </td>
                        </tr>
                        <tr className="row6">
                            <td className="rfc">CTE950627K46 </td><td className="name"> COMERCIALIZADORA TEODORIKAS </td>
                            <td className="rfc_name">CTE950627K46 <br /> COMERCIALIZADORA TEODORIKAS </td>
                            <td className="col2"> 57740 </td><td className="col3"> 601, 626 </td>
                        </tr>
                        <tr className="row7">
                            <td className="rfc">AMO8905171T1 </td><td className="name"> ALBERCAS MONTAÑO </td>
                            <td className="rfc_name">AMO8905171T1 <br /> ALBERCAS MONTAÑO </td>
                            <td className="col2"> 22000 </td><td className="col3"> 601, 626 </td>
                        </tr>
                        <tr className="row8">
                            <td className="rfc">GCA000415UX7</td><td className="name"> GRUPO DE CONSTRUCCION ARQUITECTONICA NACIONAL </td>
                            <td className="rfc_name">GCA000415UX7 <br /> GRUPO DE CONSTRUCCION ARQUITECTONICA NACIONAL </td>
                            <td className="col2"> 11830 </td><td className="col3"> 601, 626 </td>
                        </tr>
                        <tr className="row9">
                            <td className="rfc ">HHN0507087N4  </td><td className="name"> HIDRO HORTICOLA DEL NOROESTE </td>
                            <td className="rfc_name">HHN0507087N4   <br /> HIDRO HORTICOLA DEL NOROESTE </td>
                            <td className="col2"> 82198 </td><td className="col3"> 601, 626 </td>
                        </tr>
                    </tbody></table>
                <h3>
                    Campos del Request
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>formaPago:</b> Forma de pago de esta factura, tal como Efectivo, transferencia, débito, etc. <em>Consultar catálogo SAT</em>.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>metodoPago:</b> Existen tres tipos de método de pago, <b>PUE:</b> Pago en única exhibición, <b>PIP:</b> Pago inicial y en parcialidades,
                            <b>PPD:</b> Pago en parcialidades o diferido.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>lugarExpedicion:</b>Código postal del emisor de la factura, este campo sobrescribe el valor del perfil del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>receptor:</b> Un solo objeto que representa al receptor con las siguientes características.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.nombre:</b> Razón social del receptor de la factura.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.rfc:</b> RFC del receptor de la factura (Ejemplo muestra RFC de venta a mostrador).
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.usoCFDI:</b> Clave del uso de lafactura. <em><Link to="/api/catalogo/catalogo-usocfdi">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>
                            <li className="accent">
                                <p>
                                    <b><span className="clear">receptor</span>.regimen: (CFDI 4.0)</b> Clave del Régimen del receptor. <em><Link to="api/catalogo/catalogo-regimenfiscal">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>
                            <li className="accent">
                                <p>
                                    <b><span className="clear">receptor</span>.zip: (CFDI 4.0)</b> Código postal del Receptor, en caso de RFC genérico se usa el Lugar de Expedición del Emisor <em>Debe ser un String de 5 carácteres numéricos</em>
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p>
                            <b>conceptos[ ]:</b> Un array de conceptos independientes que integran la factura.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.claveProdServ:</b> Clave del producto o servicio. <em><Link to="/api/catalogo/catalogo-productoservicios">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.claveUnidad:</b> Clave de la unidad de medición. <em><Link to="/api/catalogo/catalogo-claveunidad">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.cantidad:</b> Cantidad en número entero o decimal de productos vendidos.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.descripcion:</b> Breve descripción o nombre del producto / servicio.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.valorUnitario:</b> Costo por unidad en número entero o decimal.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>conceptos.impuestos [ ]:</b> Un array de impuestos asignados a este concepto, puede incluír varios, tal como IVA retención, IVA traslado, IEPS, etc.
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <b><span className="clear">conceptos.impuestos</span>.type:</b> Tipo de impuesto, iva, ieps, isr.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">conceptos.impuestos</span>.retencion:</b> Booleano que indica si el impuesto es retenido (true) o traslado (false, DEFAULT).
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">conceptos.impuestos</span>.tasa</b> El valor del impuesto en tasa debe ser un número decimal representando el porcentaje (0.16 es 16%). <br />
                                            Si se utiliza <code>tasa</code> como en el ejemplo, es valor decimal del porciento.<br />
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">conceptos.impuestos</span>.cuota:</b> El valor del impuesto en cuota debe ser un número absoluto en $ MXN / unidad. <br />
                                            Si se utiliza <code>cuota</code> debe ser una cantidad fija (no porcentual) del impuesto, comunmente usado en IEPS.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>uuid: </b> El ID de la factura, la clave unica para identificar un CFDI.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>created: </b> Cuando fue creada la factura, indistinto de la fecha de la factura
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>json:</b> Representación del XML en JSON, util cuando se quiere usar un procesador de PDFs propio u alguna deconstrucción del contenido de la factura sin tener que parsear el XML.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>xml:</b> El string del XML de la factura, el cual es el CFDI en si.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken:</b> El token del cliente emisor, usado para relacionar facturas con su emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>status:</b> <code>true | false</code> True significa que la factura está vigente, false cuando la factura está cancelada.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>sandbox:</b> <code>true | false</code> Si la factura fué generada por cliente de prueba o producción, false es de producción, true es una factura de prueba.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/referencia/cfdi-maximum`} previous={`/api/referencia/clientes-billing`} />
            </div>
            <div className="side">
                <CodeBlock title="CFDI Emitir" requests={cfdiScripts.minimo.requests} response={cfdiScripts.minimo.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
